import React from "react"
import { Link } from "gatsby"

const CardPartner = (props) => {

  const truncateString = (str, num) => {
    if (str?.length <= num) {
      return str
    }
    return str?.slice(0, num) + '...'
  }

  return (
      <Link to={`/partners/${props.content.category}/${props.content.slug}`} data-category={props.content.category} className="filterTarget" target="_blank" data-sal="slide-up" data-sal-easing="ease">
        <div className="card-partner d-flex flex-column align-items-center justify-content-between">
          <p className="card-partner__title">{props.content.title}</p>
          <p className="card-partner__cat">{props.content.category}</p>
          <img src={props.content.image} />
          <p className="card-partner__description">{truncateString(props.content.summary, 100)}</p>
        </div>
      </Link>
    )
}

export default CardPartner
